<template>
    <v-container>

          <v-row dense class="mt-3 mb-3" justify="center">
            <v-col cols="10" md="5" align="center">
              <span class="dialog_title">Privacy Policy<br>개인 정보처리 정책</span>
            </v-col>
          </v-row>          
          
        <v-row align="center" justify="center">
            <v-col cols="12" md="8" sm="10" xs="10">            

            <p class="text-md-body-1 text-caption">
                DreamTeller는 사용자의 개인정보를 수집하지 않습니다. 우리는 사용자의 개인정보를 수집, 저장, 처리하거나 전송하지 않습니다. 또한 DreamTeller를 사용함으로써 발생하는 어떠한 개인정보도 제3자와 공유하지 않습니다.
                <br><br>사용자의 개인정보를 존중하며, 개인정보 보호에 대한 중요성을 인식하고 있습니다. 우리는 사용자의 개인정보를 수집하지 않기 때문에 어플이케이션을 사용하는 과정에서 개인정보 유출에 대한 우려 없이 안심하고 이용할 수 있습니다.
                <br><br>단순히 꿈 해석과 관련된 기능을 제공하기 위해 필요한 최소한의 정보만을 처리하고 있으며, 이는 개인정보로 분류되지 않습니다. 사용자의 꿈에 대한 기록과 해석에 대한 기록도 그 어떤 형태로도 저장되지 않습니다.
                <br><br>사용자가 안전하게 어플리케이션을 이용할 수 있도록 최선을 다하겠습니다.
            </p>

            <v-divider/><br>
            <p class="text-md-body-1 text-caption">
                DreamTeller does not collect any personal information from users. We do not gather, store, process, or transmit any personal information. Furthermore, DreamTeller does not share any personal information with third parties.
                <br><br>Respecting user privacy and recognizing the importance of personal data protection, we assure you that we do not collect any personal information throughout the use of our application. This allows users to enjoy our app without any concerns about their personal information being compromised.
                <br><br>DreamTeller solely processes the minimum necessary information required to provide dream interpretation-related features, and such information is not classified as personal information. User records of dreams and their interpretations are not stored in any form.
                <br><br>We strive to ensure a safe and secure user experience. Rest assured that we are committed to taking every possible measure to protect your privacy while using our application.
            </p>
            <v-divider/><br>
            <p class="text-md-body-1 text-caption">
                DreamTellerはユーザーの個人情報を収集しません。私たちはユーザーの個人情報を収集、保存、処理、送信することはありません。また、DreamTellerを利用することで発生するいかなる個人情報も第三者と共有しません。
                <br><br>ユーザーのプライバシーを尊重し、個人情報保護の重要性を認識しています。私たちのアプリは個人情報を収集しないため、ユーザーは安心して利用することができます。
                <br><br>DreamTellerは単に夢解釈に関連する機能を提供するために最小限の情報のみを処理しており、これらの情報は個人情報に分類されません。ユーザーの夢の記録や解釈に関する情報はいかなる形でも保存されません。
                <br><br>私たちは安全で安心なユーザーエクスペリエンスを提供するために最善の努力をいたします。私たちのアプリを利用する際には、プライバシー保護について心配することなくご利用いただけます。
            </p>

            </v-col>
        </v-row>

    </v-container>
</template>
<script>
 
export default {
    
    data: () => ({
        
    }),

    mounted() {
    },
    
    methods: {
      
    },
 
 
}
</script>
 