<template>
<div id="app">
  <v-app id="inspire">
 
    <v-app-bar elevation="0" app fixed clipped-left dense style="background-color:#7030a0;color:#ffffff; border-style:solid; border-width:0px 0px 2px 0px;border-color:#7030a0">
      <v-toolbar-title>DreamTeller</v-toolbar-title>
    </v-app-bar>
    <v-main>
      <router-view/>
    </v-main>
    <v-footer app height="auto" style="background-color:#7030a0;color:#ffffff">
      <v-spacer></v-spacer>
      <span>&copy; 2023 <a target="_blank" href="https://toritosystem.com">toritosystem</a></span>
      <v-spacer></v-spacer>
    </v-footer>
  </v-app>
</div>
</template>
 
<script>
//import { EventBus } from './util/event-bus'
export default {
 
  data: () => ({

  }),
 
  methods: {
 
  },
 
  created(){

  },
  
  mounted(){
    console.log("app mount...");
 
  },  
 
  beforeDestroy(){

  },
 
  beforeUnmount() {

  },
 
}
</script>
 
<style>
 /* 여기는 기본 태그들에 스타일을 먹이는 곳인듯 */
</style>
 
<style scoped>
 a {
  color:white !important;
  text-decoration: none;
 }
 
</style>
 
<!-- 전체 폰트 -->
<style lang="scss">
 
</style>