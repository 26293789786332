<template>
    <v-container>

    <!-- 프로그레스 
    <v-row>
      <v-dialog 
        persistent scrollable overlay-color="#00000040" v-model="loading"
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        >
        <v-card class="dialog_round pa-10">
          <v-row>
            <v-progress-linear color="purple" indeterminate/>
          </v-row>
          <v-row class="ma-5" justify="center">
            <v-col cols="12" align="center">
              <label>잠시만 기다려주세요. A.I가 꿈해몽중입니다.</label>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </v-row>
    -->
    <v-row align="top" justify="center" dense>
      <iframe 
        src="https://ads-partners.coupang.com/widgets.html?id=663801&template=carousel&trackingCode=AF9817712&subId=&width=680&height=140" 
        width="680" height="140" frameborder="0" scrolling="no" referrerpolicy="unsafe-url"
        />
    </v-row>

    <!-- 
    <v-row justify="center" dense>
      <v-col cols="12" align="center">
      <ins class="kakao_ad_area" style="display:none;"
      data-ad-unit = "DAN-fLbI042hZtEGLUg5"
      data-ad-width = "300"
      data-ad-height = "250"></ins>                
      </v-col>
    </v-row>
    -->

    <v-row align="top" justify="center" class="mt-md-5" dense>

      <v-col cols="12" md="2" v-if="tenping_left != null" align="center" order="2" order-md="1">
        <v-row justify="center">
          <v-col cols="11" v-for="item in tenping_left" :key="item.ContentID">
            <v-card @click="redirectToLink(item.Link)">
              <v-img
                cover height="150"
                :src="item.MImage"
              ></v-img>
              <v-card-text>{{ item.ContentTitle }}</v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="8" order="1" order-md="2">

          <v-row justify="center">
            <v-col v-if="message.length == 0" cols="12" md="8" align="center" class="mt-1">
                <span>자신의 상황과 꿈의 내용을 최대한 상세하게 아래 채팅창에 입력 후 전송버튼을 눌러주세요.<br>내용이 자세할 수록 좋은 풀이가 가능합니다.</span>
            </v-col>
            <v-col v-if="message.length > 0" cols="12" md="8" align="center" class="mt-1">
                <span>당신이 입력한 꿈의 내용</span>
            </v-col>
            <v-col v-if="message.length > 0" cols="12" md="8" align="center">
                <v-card class="mb-1 pa-3">
                  <span>{{ message }}</span>
                </v-card>
            </v-col>
          </v-row>

          <v-row justify="center">
            
            <v-col v-if="loading" cols="12" md="8" align="center" class="mt-3">
                <span>잠시만 기다려주세요. A.I가 꿈해몽중입니다.</span>
                <v-progress-linear indeterminate/>
                <v-row align="top" justify="center" class="mt-5" dense>
                  <ins class="kakao_ad_area" style="display:none;"
                  data-ad-unit = "DAN-fLbI042hZtEGLUg5"
                  data-ad-width = "300"
                  data-ad-height = "250"></ins>    
                </v-row>
            </v-col>
            <v-col v-if="resultMsg.length > 0" cols="12" md="8" align="center" class="mt-3">
                <span>DreamTeller의 꿈해몽 풀이입니다.</span>
            </v-col>
            <v-col v-if="resultMsg.length > 0" cols="12" md="8" align="center">
                <v-card style="overflow-y:auto" class="mb-1 pa-3">
                  <span class="" v-typing="resultMsg"/>
                </v-card>
            </v-col>
          </v-row>

          <v-row class="mt-15 mb-15">

          </v-row>

          <v-row justify="center" align="center" class="fixed_bottom ma-0 pt-5 pb-5" dense style="background-color:white;z-index:9999">
            <v-col cols="9" md="6" align="center">
              <v-textarea
                v-model="inputMessage"
                label="꿈의 내용을 입력하세요"
                rows="1" 
                outlined hide-details
              ></v-textarea>
            </v-col>
            <v-col cols="2" md="1"  :align="$vuetify.breakpoint.smAndDown ? 'center' : 'start'"
                class="ma-0 pa-0">
              <v-btn @click="sendMessage" icon>
                <v-icon v-if="!loading">mdi-send-outline</v-icon>
                <v-progress-circular v-else indeterminate></v-progress-circular>
              </v-btn>
            </v-col>
          </v-row>

      </v-col>

      <v-col cols="12" md="2" v-if="tenping_right != null" align="center" order="3" order-md="3">
        <v-row justify="center">
          <v-col cols="11" v-for="item in tenping_right" :key="item.ContentID">
            <v-card @click="redirectToLink(item.Link)">
              <v-img
                cover height="150"
                :src="item.MImage"
              ></v-img>
              <v-card-text>{{ item.ContentTitle }}</v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

    </v-row>


    </v-container>
</template>
<script>
 
export default {
    
    data: () => ({
        message:"",
        resultMsg:"",
        inputMessage: "",
        loading:false,

        tenping_left:null,
        tenping_right:null,
        
        options: {
            text: "타이핑 효과를 만드는 예제입니다.",
            
        },        
    }),
    
    directives: {
        typing: {
            bind(el, binding, vnode) {

                console.log("typing... : " + JSON.stringify(binding.value));

                const textmsg = binding.value;
                let index = 0;

                const type = () => {
                if (index < textmsg.length) {
                    el.textContent += textmsg[index++];
                    //vnode.textContent += textmsg[index++];
                    setTimeout(type, 75);
                }
                };

                type();
            },
        },
    },

    mounted() {
      this.loadtenping();
    },
    
    methods: {
        sendMessage() {
            
            if(this.loading) return;

            if (this.inputMessage.trim() !== "") {
                this.message = this.inputMessage;
                this.resultMsg = "";
                this.loadpage();
                this.inputMessage = "";
            }
        },

      loadtenping(){

        this.$http.post(this.$host+'/getTenping',
          {
            PageSize:8,
            CampaignType:237,
            MinClickPoint:100,
            MinCurrentPoint:1000
          },{headers: {}})
        .then((result)=>{
          if(result.data.resultCode == 0){
            
            const list = result.data.resultData.List;
            this.tenping_left = list.slice(0, list.length/2);
            this.tenping_right = list.slice(list.length/2, list.length);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
        });

      },

      loadpage(){

        if(this.inputMessage == null || this.inputMessage == undefined || this.inputMessage.length < 1){
          this.$alert("내용을 입력하세요");
          return;
        }

          this.loading = true;
          this.$http.post(this.$host+'/getDream',
            {
                message : this.inputMessage
            },{headers: {}})
          .then((result)=>{
              this.loading = false;
              if(result.data.resultCode == 0){
                this.resultMsg = result.data.resultData;
              }else{
                this.$alert("내용을 가져오지 못했습니다.");
              }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert(error.message);
            }
          });

      },
      
      
    },
 
 
}
</script>
 
<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

.fixed_bottom{
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 35px;
}

.messages-display {
  max-height: 400px;
  overflow-y: auto;
}
</style>