import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router'

//라이브러리
import axios from 'axios'               //http library
import querystring from 'querystring'   //axios랑 함께 써야하는 라이브러리 (post 파라메터 전송)
import VueSessionStorage from 'vue-sessionstorage'  //저장소

import VueSimpleAlert from "vue-simple-alert";
Vue.use(VueSimpleAlert);

//화면단
import Welcome from './components/Welcome.vue'
import Main from './components/Main.vue'
import Privacy from './components/Privacy.vue'

//전역변수
Vue.prototype.$http = axios
Vue.prototype.$qstr = querystring
//Vue.prototype.$host = "http://192.168.0.3:8080";   //테스트 서버
Vue.prototype.$host = "https://taroapi-jv5xa2kqkq-du.a.run.app";   //실서버


//플러그인 사용
Vue.use(VueRouter)
Vue.use(VueSessionStorage)
Vue.config.productionTip = false


//라우터 인스턴스 생성
const router = new VueRouter({
  mode:'history',
  routes : [
      {path : "/",          component:Welcome}
    , {path : "",           component:Welcome}
    , {path : "/Main",      component:Main}
    , {path : "/Privacy",   component:Privacy}
  ],
})


new Vue({
    vuetify
  , render: h => h(App)
  , router
}).$mount('#app')
