<template>
    <v-container fluid>

        <v-row align="top" justify="center" class="mt-md-5" dense>


          <v-col cols="12" md="6" order="1" order-md="2">
            <v-card class="text-center" :class="$vuetify.breakpoint.smAndDown ? 'pa-0' : 'pa-5'" 
                :elevation="$vuetify.breakpoint.smAndDown ? 0 : 2">
              <v-img
                class="rounded"
                :src="require('@/assets/gimg.png')"
                aspect-ratio="2"
              ></v-img>

              <div class="text-md-h4 text-h4 mt-md-8 mt-5" style="text-align:center">
                AI 꿈해몽 전문가
              </div>
              <div class="text-md-h5 text-subtitle-1 mt-1" style="text-align:center">
                DreamTeller에 오신 것을 환영합니다!
              </div>
              <div class="text-md-body-1 text-body-2 mt-md-5 mt-3" style="text-align:center">
                최신 인공지능 기술을 활용한 우리의 꿈해몽 서비스는 꿈의 숨겨진 의미를 분석하여 당신의 삶에 대한 통찰력 있는 조언을 제공합니다.
              </div>
              <div class="text-md-body-1 text-body-2 mt-2" style="text-align:center">
                우리의 인공지능은 꿈을 해석하는 전통적인 방법뿐만 아니라, 신경망 기반의 분석을 통해 꿈의 복잡한 패턴을 이해하고 개인화된 해석을 제공합니다.
              </div>
              <div class="text-md-body-1 text-body-2 mt-2 mb-5" style="text-align:center">
                당신의 꿈이 당신에게 무슨 이야기를 전하려고 할까요? 지금 AI 꿈해몽 전문가를 통해 깊이 있는 이해를 얻고, 삶의 가치 있는 통찰력을 발견하세요. 지금 바로 시작하세요!
              </div>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" class="mb-5" large to="/Main">
                  꿈해몽 하기
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-col>


        </v-row>


    </v-container>
</template>
<script>
 
export default {
    
    data: () => ({
      
    }),

    mounted() {
      
    },
    
    methods: {
      

    },
 
 
}
</script>
 
<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>
.rounded {
  border-radius: 12px;
}
</style>